import React from 'react';
import { RichText } from 'prismic-reactjs';

import css from './credits.module.scss';
import shared from 'styles/shared.module.scss';

const Credits = ({ data: { prismic } }) => {
  const content = prismic.allCreditss.edges[0];
  if (!content) return null;

  const credits = content.node;
  return (
    <>
      <section className={css.host}>
        <div className={`${css.container} ${shared.maxwidth}`}>
          <h1 className={shared.sectionTitle}>
            {RichText.asText(credits.title)}
          </h1>
          <div className={css.content}>
            {credits.images.map((image, idx) => (
              <div className={css.item} key={idx}>
                <h2 className={css.name}>{image.image_title}</h2>
                <h2 className={css.page}>
                  <strong>Author: </strong>
                  <span>{image.image_author}</span>
                </h2>
                <h3 className={css.page}>
                  <strong>Page: </strong>
                  <span>{image.image_page}</span>
                </h3>
                <h3 className={css.page}>
                  <strong>License: </strong>
                  <a
                    href={image.image_license.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={css.source}
                  >
                    {image.image_license.url}
                  </a>
                </h3>
                <a
                  href={image.image_source.url}
                  className={css.source}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link to source
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Credits;
export const pageQuery = graphql`
  query AllCredits {
    prismic {
      allCreditss {
        edges {
          node {
            title
            images {
              image_author
              image_license {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              image_source {
                ... on PRISMIC__ExternalLink {
                  _linkType
                  url
                }
              }
              image_page
              image_title
            }
          }
        }
      }
    }
  }
`;
